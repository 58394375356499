import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"

import AutomationPayInvoiceOnline from "src/components/features/automation/AutomationPayInvoiceOnline"
import TestimonialCard from "src/components/TestimonialCard"

import chrisAvatar from "src/assets/img/testimonials/chris-medium600.jpg"

const FeaturePracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Online payment"
        pathname="/features/online-payment"
        description="With Carebit, patients can pay online with a credit or debit card 24/7. It's medical billing modernised."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Online payment</h1>
              <p className="u-whiteTranslucent">
                As part of Carebit, self-pay patients and insured patients with
                excesses to pay can settle their invoice online using a debit or
                credit card. Receipts are emailed automatically to them while
                the doctor receives payment within 7 days. No more waiting for
                cheques to clear or having to handle cash.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <AutomationPayInvoiceOnline />
            </Col>
          </Row>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Professor Christopher Eden"
                  authorSubtext="Leading robotic radical prostatectomy expert, Santis"
                  avatarSrc={chrisAvatar}
                >
                  <p>
                    “Online payment has proved very popular with patients, who
                    appreciate its convenience, as well as with us. It has
                    improved cashflow and checking whether payment has been made
                    is now easier than ever.”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default FeaturePracticeManagement
