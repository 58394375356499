import React from "react"
import { Row, Col } from "reactstrap"

import Heading from "../../../components/index/Heading"

import automationPayInvoiceOnline from "../../../assets/video/automation-pay-invoice-online.gif"

const AutomationPayInvoiceOnline = () => (
  <section>
    <Row className="u-centerFlex">
      <Col lg={5} md={12} className="SplitContentRow-graphic">
        <img
          alt="Carebit - pay invoices online"
          src={automationPayInvoiceOnline}
          className="u-outline"
          style={{ width: "100%" }}
        />
      </Col>
      <Col
        lg={{ size: 6, offset: 1 }}
        md={{ size: 12 }}
        className="SplitContentRow-text"
      >
        <Row>
          <Heading title="Convenient and instant">
            Patients can pay their invoice online
          </Heading>
        </Row>
        <p className="u-midGrey">
          As part of Carebit, all self-pay patients can pay their invoices
          online 24/7. It’s fast and convenient for them to use on desktop,
          smartphone or tablet, the doctor gets paid within 7 days and Carebit
          automatically records the payment and emails a receipt to the patient.
          No more time spent cashing cheques and poring over bank statements.
        </p>
      </Col>
    </Row>
  </section>
)

export default AutomationPayInvoiceOnline
